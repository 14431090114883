<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Country Name" vid="country_name_id" rules="required|min_value:1" v-slot="{ errors }">
              <b-form-group
                label-for="country_name_id">
                <template v-slot:label>
                  {{ $t('globalTrans.country_name') }} <span class="text-danger">*</span>
                </template>
                <b-form-select
                  plain
                  id="country_name_id"
                  :options="tradeTariffCountryList"
                  v-model="formData.country_name_id"
                  :disabled="disableCountry"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Capital Name" vid="capital_name_id" rules="required|min_value:1" v-slot="{ errors }">
              <b-form-group
                label-for="capital_name_id">
                <template v-slot:label>
                  {{ $t('tradeTariffConfig.capital_name') }} <span class="text-danger">*</span>
                </template>
                <b-form-select
                  plain
                  id="capital_name_id"
                  :options="capitalList"
                  v-model="formData.capital_name_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Port Type" vid="port_type_id" rules="required|min_value:1" v-slot="{ errors }">
              <b-form-group
                label-for="port_type_id">
                <template v-slot:label>
                  {{ $t('tradeTariffConfig.port_type') }} <span class="text-danger">*</span>
                </template>
                <b-form-select
                  plain
                  id="port_type_id"
                  :options="portTypeList"
                  v-model="formData.port_type_id"
                  :disabled="disablePType"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <!-- <b-row class="p-2">
          <b-col style="background-color: var(--gray-ddd);" lg="4" class="offset-lg-4 p-2">
            <template>
              <h5 style="" class="text-center">{{ $t('tradeFairConfig.committee_member_info') }}</h5>
            </template>
          </b-col>
        </b-row> -->
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Port Name (En)" vid="port_name_en" v-slot="{ errors }">
              <b-form-group
                label-for="port_name_en">
                <template v-slot:label>
                  {{ $t('tradeTariffConfig.port_name') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="port_name_en"
                  v-model="detailsFormData.port_name_en"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <p class="text-danger" style="font-size: 0.875em;"> {{ nameEnError + ' ' + alreadyAddedMsg }} </p>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Port Name (Bn)" vid="port_name_bn" v-slot="{ errors }">
              <b-form-group
                label-for="port_name_bn">
                <template v-slot:label>
                  {{ $t('tradeTariffConfig.port_name') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="port_name_bn"
                  v-model="detailsFormData.port_name_bn"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <p class="text-danger" style="font-size: 0.875em"> {{ nameBnError }} </p>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12">
            <ValidationProvider name="Port Description" vid="port_description" v-slot="{ errors }">
              <b-form-group
                label-for="port_description">
                <template v-slot:label>
                  {{ $t('tradeTariffConfig.port_description') }}
                </template>
                <b-form-textarea
                  id="port_description"
                  v-model="detailsFormData.port_description"
                  rows="3"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-textarea>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <div class="row">
          <div class="col-sm-3"></div>
          <div class="col text-right">
            <b-button v-if="locationIndex !== ''" variant="danger" class="btn btn-danger text-light btn-sm mr-1" @click="cancelLocation()">{{ $t('globalTrans.cancel') }}</b-button>
            <b-button @click="addItem()" variant="success" class="mr-2 btn-sm">{{ locationIndex !== '' ? $t('globalTrans.update') : $t('globalTrans.add')}}</b-button>
          </div>
        </div>
        <br/>
        <b-table-simple bordered v-if="formData.details.length">
          <thead>
            <tr class="bg-primary">
              <th style="width:20%">{{ $t('globalTrans.sl_no') }}</th>
              <th style="width:60%">{{ $t('tradeTariffConfig.port_name') }}</th>
              <th style="width:60%">{{ $t('tradeTariffConfig.port_description') }}</th>
              <th style="width:20%">{{ $t('globalTrans.action') }}</th>
            </tr>
          </thead>
          <b-tbody>
              <b-tr v-for="(item, index) in formData.details" :key="index">
                  <b-td>
                      {{ $n(index + 1) }}
                  </b-td>
                  <b-td>
                    {{ currentLocale == 'bn' ? item.port_name_bn : item.port_name_en }}
                  </b-td>
                  <b-td>
                    {{ item.port_description }}
                  </b-td>
                  <b-td>
                    <span class="d-flex text-center">
                      <!-- <b-button @click="editLocation(item, index)" title="Location Edit" class="btn btn-success btn-sm mr-1"><i class="ri-ball-pen-fill m-0"></i></b-button> -->
                      <b-button type="button" @click="deleteItem(index)" class="btn btn- iq-bg-danger btn-sm ml-1" variant="danger"><i class="ri-delete-bin-line m-0"></i></b-button>
                    </span>
                  </b-td>
              </b-tr>
          </b-tbody>
        </b-table-simple>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { tradeTariffServiceBaseUrl } from '@/config/api_config'
import { portInfoStore, portInfoUpdate } from '../../api/routes'
export default {
  name: 'Form',
  props: ['id'],
  data () {
    return {
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      capitalList: [],
      nameEnError: '',
      disablePType: false,
      disableCountry: false,
      alreadyAddedMsg: '',
      nameBnError: '',
      locationIndex: '',
      formData: {
        country_name_id: 0,
        capital_name_id: 0,
        port_type_id: 0,
        details: []
      },
      detailsFormData: {
        port_name_en: '',
        port_name_bn: '',
        port_description: ''
      }
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getCapitalInfo()
      this.formData = tmp
      this.disablePType = true
      this.disableCountry = true
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    tradeTariffCountryList () {
      return this.$store.state.TradeTariffService.commonObj.tradeTariffCountryList.filter(item => item.status === 1)
    },
    portTypeList () {
      return this.$store.state.TradeTariffService.commonObj.portTypeList
    }
  },
  watch: {
    'formData.country_name_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.capitalList = this.getCapitalList(newVal)
      } else {
        this.capitalList = []
      }
    }
  },
  methods: {
    getCapitalList (id) {
      return this.$store.state.TradeTariffService.commonObj.capitalInfoList.filter(item => item.status === 1 && item.country_name_id === id)
    },
    cancelLocation () {
      this.detailsFormData = {}
      this.locationIndex = ''
      this.$refs.form1.reset()
    },
    editLocation (item, index) {
      this.detailsFormData = Object.assign({}, item)
      this.locationIndex = index
    },
    async addItem () {
      if (this.detailsFormData.port_name_en && this.detailsFormData.port_name_bn) {
        this.nameEnError = ''
        this.nameBnError = ''
        this.alreadyAddedMsg = ''
        const objExist = this.formData.details.find(detail => detail.port_name_en === this.detailsFormData.port_name_en)
        if (typeof objExist === 'undefined') {
          if (this.locationIndex !== '') {
            this.formData.details[parseInt(this.locationIndex)] = this.detailsFormData
            this.disablePType = true
            this.disableCountry = true
          } else {
            this.formData.details.push(JSON.parse(JSON.stringify(this.detailsFormData)))
            this.disablePType = true
            this.disableCountry = true
          }

          this.detailsFormData = {}
          this.locationIndex = ''
          this.$nextTick(() => {
            this.$refs.form.reset()
          })
        } else {
          this.alreadyAddedMsg = 'Port Name Already Added!!!'
        }
      } else {
        this.alreadyAddedMsg = ''
        this.nameEnError = this.detailsFormData.name_en > 0 ? '' : 'Port Name (En) is not valid.'
        this.nameBnError = this.detailsFormData.name_bn > 0 ? '' : 'Port Name (Bn) is not valid.'
      }
    },
    deleteItem (index) {
      this.formData.details.splice(index, 1)
    },
    async saveUpdate () {
      if (!this.formData.details.length) {
        return this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: this.$t('tradeTariffConfig.added_msg'),
          color: 'red'
        })
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.id) {
        result = await RestApi.putData(tradeTariffServiceBaseUrl, `${portInfoUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(tradeTariffServiceBaseUrl, portInfoStore, this.formData)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('TradeTariffService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-form')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getCapitalInfo () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
