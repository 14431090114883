<template>
    <b-row>
      <b-row>
        <b-col lg="12" sm="12">
          <body-card>
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="12" sm="12">
                        <div>
                          <b-table-simple hover small>
                            <b-tr>
                              <b-th style="width:20%">{{ $t('globalTrans.country_name') }}</b-th>
                              <b-th style="width:2%">:</b-th>
                              <b-td style="width:78%">{{ (this.$i18n.locale == 'bn') ? detailsData.country_name_bn : detailsData.country_name_en }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th style="width:20%">{{ $t('tradeTariffConfig.capital_name') }}</b-th>
                              <b-th style="width:2%">:</b-th>
                              <b-td style="width:78%">{{ (this.$i18n.locale=='bn')? detailsData.capital_name_bn : detailsData.capital_name_en }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th style="width:20%">{{ $t('tradeTariffConfig.port_type') }}</b-th>
                              <b-th style="width:2%">:</b-th>
                              <b-td style="width:78%">{{ (this.$i18n.locale == 'bn') ? detailsData.port_name_bn : detailsData.port_name_en }}</b-td>
                            </b-tr>
                          </b-table-simple>
                          <b-table-simple bordered hover small>
                              <b-tbody>
                                  <!-- <tr>
                                      <b-th colspan="2" class="text-center p-2" style="background: #e3e3e3">{{ $t('tradeFairConfig.committee_member_info') }}</b-th>
                                  </tr> -->
                                  <tr>
                                    <b-th style="width:20%" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                    <b-th style="width:30%" class="text-left">{{ $t('tradeTariffConfig.port_name') }}</b-th>
                                    <b-th style="width:50%" class="text-left">{{ $t('tradeTariffConfig.port_description') }}</b-th>
                                  </tr>
                                  <tr v-for="(detail, inx) in detailsData.details" :key="inx">
                                    <b-td class="text-center">{{ $n(inx + 1) }}</b-td>
                                    <b-td class="text-left">{{ $i18n.locale === 'en' ? detail.port_name_en : detail.port_name_bn }}</b-td>
                                    <b-td class="text-left">{{ detail.port_description }}</b-td>
                                  </tr>
                              </b-tbody>
                          </b-table-simple>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
              </b-row>
            </template>
            <!-- <pre>{{detailsData}}</pre> -->
          </body-card>
        </b-col>
      </b-row>
    </b-row>
</template>
<script>
export default {
    name: 'Details',
    props: ['id'],
    data () {
      return {
      valid: null,
      loading: false,
      errors: [],
      detailsData: []
      }
    },
    created () {
    if (this.id) {
      const tmp = this.getPortInfo()
      this.detailsData = tmp
    }
    },
    methods: {
    getRoll (id) {
      const dataRoll = this.$store.state.TradeFairService.commonObj.CommitteeRollList.find(item => item.status === 1 && item.value === id)
      if (typeof dataRoll !== 'undefined') {
        return this.currentLocale === 'bn' ? dataRoll.text_bn : dataRoll.text_en
      } else {
        return ''
      }
    },
    getPortInfo () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
    }
}
</script>
